<template>
    <div class="point_charge_wrap">
        <div class="point_charge_title">
          {{ $t('mingle_charge') }}
        </div>
        <div class="charge_price_list_box">
          <div class="charge_price_list" v-for="index in 4" :key="`cp${index}`" :class="{active: nowSelect === index}" @click="nowTab(index)">
            <div class="charge_price_top">
              <div class="charge_bonus_box" v-show="!(index === 1 || index === 3)">
                <div class="charge_bonus">
                  <span>10,000 + 2,000</span>
                </div>
              </div>
              <div class="charge_mingle_box" :class="{no_bonus: index === 1 || index === 3}">
                <div class="charge_mingle_img"></div>
                <div class="charge_mingle_point">12,000Mg</div>
              </div>
            </div>
            <div class="charge_price_bottom">
              <div class="charge_price">￦ 100,000</div>
            </div>
          </div>
<!--          <div class="charge_price_list">-->
<!--            <div class="charge_price_top">-->
<!--&lt;!&ndash;              <div class="charge_bonus_box">&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="charge_bonus">&ndash;&gt;-->
<!--&lt;!&ndash;                  <span>10,000+2,000</span>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--              <div class="charge_mingle_box no_bonus">-->
<!--                <div class="charge_mingle_img"></div>-->
<!--                <div class="charge_mingle_point">12,000Mg</div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="charge_price_bottom">-->
<!--              <div class="charge_price">￦ 100,000</div>-->
<!--            </div>-->
<!--          </div>-->
        </div>
<!--        <div class="notification_box">-->
<!--          <ul class="notification_text">-->
<!--            <li type="disc">관리자페이지에서 입력한 안내사항 노출...</li>-->
<!--            <li type="disc">관리자페이지에서 입력한 안내사항 노출...</li>-->
<!--            <li type="disc">관리자페이지에서 입력한 안내사항 노출...</li>-->
<!--          </ul>-->
<!--        </div>-->
        <div class="charge_notification_wrap">
          <div class="terms_check" :class="{ active : isActive}" @click="isActive = !isActive"></div>
          <span class="terms_text" v-html="$t('charge_notification_text')"></span>
        </div>
        <div class="charge_btn_wrap">
          <button class="charge_btn">{{ $t('charge') }}</button>
        </div>
        <div class="check_validator center mt0">
          <span>{{ $t('charge_validator_text') }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "PointWriteDefaultLayout",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
          isActive: false,
          errorMsg: "",
          nowSelect: 1,
        }
    },
    beforeRouterEnter() {
    },
    async created() {
      openTerms = this.openTerms;

    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
      movePage(url) {
        this.$router.push(url);
      },
      nowTab(index){
        this.nowSelect = index;
      },
      openTerms(name) {
        this.$modal.show(name);
      },
    },
    watch: {},
}
</script>

<style scoped>

</style>
